<template>
  <div>
    <div v-if="isThereData">
    </div>
    <!-- Modal para probar -->
    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span>Sustitución de variables</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <FormulateForm
          ref="sustitutionForm"
          name="sustitutionForm"
          v-model="formValues"
          :errors="response.errors"
          @submit="submit"
        >
          <div class="card">
            <div class="card-header">
              Asigne un valor para cada variable
            </div>
            <div class="card-body">
              <!--FormulateInput
                v-for="item in items"
                :key="item.name"
                v-bind="item"
              /-->
              <FormulateInput
                v-for="variable in variables"
                :key="variable.label"
                v-bind="variable"
              />
            </div>
          </div>
        </FormulateForm>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.inversionistaForm ? $refs.inversionistaForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="false"
            :isCreating="true"
            @on-cancel="resetForm"
            @on-create="submitForm"
            @on-update="submitForm"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
    <!-- Table -->
    <b-pagination size="sm" align="fill"
                  v-if="(getTotalItems / perPage) > 1"
                  v-model="currentPage"
                  :total-rows="getTotalItems"
                  :per-page="perPage"
                  :disabled="!getAvailableList"
                  aria-controls="plantillas-contratos-table"
    ></b-pagination>
    <b-table
      id="plantillsaContratosTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :filter-function="filterFunction"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron plantillas con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(identificacion)="row">
        <filtered-row :value="row.item.identificacion" :filter="filter"/>
      </template>

      <template #cell(archivo.original_name)="row">
        <b-link class="text-sm text-center text-primary w-100" @click="onDownloadFile(row.item.archivo)">
          <filtered-row :value="row.item.archivo.original_name" :filter="filter"/>
        </b-link>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="plantillasContratosModule/getResourceToEdit"
            deletePath="plantillasContratosModule/deleteResource"
            :editPermissions="['actualizar plantillas contratos']"
            :deletePermissions="['eliminar plantillas contratos']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="12">
                <b-card
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-card-header>
                    Variables
                    <b-button @click="onShowModal(row.item.id, row.item.variables)" variant="success" size="sm">Probar</b-button>
                  </b-card-header>
                  <span class="text-sm text-left w-100">
                    <ul>
                      <li v-for="variable in row.item.variables" v-bind:key="variable.id">
                        <b-button variant="link">
                          {{ variable.identificacion }}
                        </b-button>
                      </li>
                    </ul>
                  </span>
                </b-card>
              </b-col>
            </b-row>
            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '@/components/Common/Table/FilteredRow'

const API_MODULE = 'plantillasContratosModule'

const formValues = () => ({
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'PlantillasContratosList',

  components: {
    FilteredRow
  },

  props: {
    filter: {
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      modal: false,

      id: '',

      variables: [],

      items: [
        {
          name: 'email',
          label: 'Your email',
          validation: 'required|email'
        }
      ],

      fields: [
        { key: 'identificacion', label: 'Nombre Plantilla', sortable: true },
        { key: 'archivo.original_name', label: 'Archivo' },
        { key: 'show_details', label: 'Detalles' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0,

      filterOn: ['identificacion'],

      isLoading: false,

      formValues: formValues(),

      response: response()
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Notificaciones')
    },

    async submitForm () {
      this.response = response()

      this.isLoading = true
      const result = await this.$store.dispatch(
        `${API_MODULE}/preview`,
        { id: this.id, variables: this.variables, values: this.formValues }
      )
      this.isLoading = false

      await this.$onDownload(result, 'preview.docx')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return row.identificacion.toLowerCase().includes(filter.toLowerCase()) ||
        row.archivo.original_name.toLowerCase().includes(filter.toLowerCase())
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${API_MODULE}/setResourceToEdit`, null)
      // this.$formulate.reset('notificacionesForm')
      // this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onShowModal (id, variables) {
      this.modal = true
      this.id = id
      this.variables = variables.map(item => ({ label: item.identificacion, name: item.identificacion, validation: 'required' }))
      this.$emit('input', this.modal)
    },

    async onDownloadFile (archivo) {
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.original_name)

      this.$notify({ message: `Descarga de archivo ${archivo.original_name}.` })
    },

    includeFilter (value, filter) {
      if ((filter === null) || (filter === '') || (value === null)) {
        return false
      }
      return value.toLowerCase().includes(filter.toLowerCase())
    },

    leftContent (value, filter) {
      return value.substring(0, value.toLowerCase().indexOf(filter.toLowerCase()))
    },

    filterContent (value, filter) {
      return value.substring(
        value.toLowerCase().indexOf(filter.toLowerCase()),
        value.toLowerCase().indexOf(filter.toLowerCase()) + filter.length)
    },

    rightContent (value, filter) {
      return value.substring(value.toLowerCase().indexOf(filter.toLowerCase()) + filter.length)
    }
  }
}
</script>
this.$emit('on-got-item')
