<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <!-- <b-row v-if="isEditingResource">
        <b-col class="d-flex flex-row">
          <b-badge variant="dark" class="mb-3 mr-2" v-text="getLastAccessText"></b-badge>
          <b-badge variant="success" class="mb-3 mr-2" v-text="getCreationDate"></b-badge>
          <b-badge variant="warning" class="mb-3" v-text="getModificationDate"></b-badge>
        </b-col>
      </b-row> -->

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <FormulateForm
          ref="plantillasContratosForm"
          name="plantillasContratosForm"
          v-model="formValues"
          :errors="response.errors"
          @submit="submit"
        >
          <div class="card">
            <div class="card-header">
              Nueva plantilla
            </div>
            <div class="card-body">
              <FormulateInput
                name="identificacion"
                label="Nombre de la plantilla"
                validation="bail|required"
                type="text"
              >
              </FormulateInput>

              <!--FormulateInput
                type="file"
                name="file"
                label="Archivo"
                help="Seleccione el archivo que corresponda a la plantilla"
                validation="mime:application/docx"
              /-->

              <b-form-group>
                <b-form-file v-model="files" accept=".docx" :state="Boolean(files.length)" multiple plain></b-form-file>
              </b-form-group>
            </div>
          </div>
        </FormulateForm>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.inversionistaForm ? $refs.inversionistaForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const formValues = () => ({
  identificacion: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const API_MODULE = 'plantillasContratosModule'

export default {
  name: 'PlantillasContratosForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  async created () {
    //
    // Obtiene todos los roles del sistema
    await this.$store.dispatch('roleModule/getSystemRoles')
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      response: response()
    }
  },

  computed: {
    //
    // User module
    ...mapState(API_MODULE, ['editingResource']),
    ...mapGetters(API_MODULE, ['isEditingResource', 'getSelectedDeptoData']),

    showFormTitle () {
      return this.isEditingResource ? 'Editar plantilla' : 'Crear plantilla'
    },

    getLastAccessText () {
      if (!this.isEditingResource) return ''

      return this.editingResource.ultimo_acceso === 'Sin registro de ingreso'
        ? this.editingResource.ultimo_acceso
        : `Último acceso: ${this.$formatDate(this.editingResource.ultimo_acceso)}`
    },

    getCreationDate () {
      if (!this.isEditingResource) return ''

      return `Creación: ${this.$customFormatDate(this.editingResource.fecha_creacion, 'DD/MMMM/YYYY')}`
    },

    getModificationDate () {
      if (!this.isEditingResource) {
        return ''
      }

      return `Modificación: ${this.$customFormatDate(
        this.editingResource.ultima_modificacion,
        'DD/MMMM/YYYY'
      )}`
    },

    //
    // Guarda y obtiene la información de Vuex
    files: {
      get () {
        return this.$store.state.plantillasContratosModule.importFiles.files
      },
      set (value) {
        this.$store.commit('plantillasContratosModule/setFilesToImport', value)
      }
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        if (value) {
          const { id, asunto, mensaje, remitente } = this.editingResource
          this.formValues.id = id
          this.formValues.asunto = asunto
          this.formValues.mensaje = mensaje
          this.formValues.remitente_id = remitente.id

          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingResource" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateNotificacion(this.formValues)
        : await this.createNotificacion(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createNotificacion (form) {
      return await this.$store.dispatch(
        `${API_MODULE}/createResource`,
        { form: form, files: this.$store.getters['plantillasContratosModule/getImportFiles'] }
      )
    },

    async updateNotificacion (form) {
      return await this.$store.dispatch(`${API_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${API_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('plantillasContratosForm')
      this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    }
  }
}
</script>
<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
